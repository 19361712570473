import type { ReactNode } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Center, Spinner, Image, type CenterProps, type ImageProps } from '@chakra-ui/react'
import { isUndefined } from 'shared-utils'
import { MdOutlineAutoAwesome } from 'react-icons/md'

export type AvatarProps = CenterProps & {
  imageProps: Partial<ImageProps>
  fallback?: ReactNode
  isLoading?: boolean
}

export const Avatar = ({
  imageProps: { src, alt, ...imageProps },
  fallback,
  children,
  isLoading: _isLoading,
  ...props
}: AvatarProps) => {
  const [isError, setIsError] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const isLoading = _isLoading

  useEffect(() => {
    setIsError(false)
  }, [src])

  const onError = useCallback(() => {
    setIsError(true)
  }, [])

  const onLoad = useCallback(() => {
    setIsLoaded(true)
  }, [])

  const renderFallback = () => (isUndefined(fallback) ? <MdOutlineAutoAwesome /> : <>{fallback}</>)

  return (
    <Center
      className="assistant-avatar"
      borderRadius="50%"
      color="#fff"
      h="32px"
      overflow="hidden"
      position="relative"
      w="32px"
      {...(isLoaded ? {} : { bg: 'primary' })}
      {...props}>
      <>
        {!!src && !isError ? (
          <Image
            alt={alt || ''}
            objectFit="cover"
            onError={onError}
            onLoad={onLoad}
            sizes="64px"
            src={src}
            {...imageProps}
          />
        ) : (
          renderFallback()
        )}

        {isLoading && (
          <Center
            backdropFilter="blur(4px)"
            bg="rgba(0,0,0,0.1)"
            h="100%"
            position="absolute"
            w="100%"
            zIndex={2}>
            <Spinner />
          </Center>
        )}

        {children}
      </>
    </Center>
  )
}
