import type { FC, ReactNode } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { Box, Center, Flex, HStack, VStack } from '@chakra-ui/react'
import type { AssistantMessageFE, AssistantMessageProductDTO } from 'ecosystem'
import { IoMdAlert } from 'react-icons/io'
import GrandText from 'ui/common/GrandText'
import GrandTooltip from 'ui/common/GrandTooltip'
import { useAssistantContext } from './asssistantContext'
import { Avatar } from './Avatar'

export interface ChatMessageProps extends StackProps {
  msg: AssistantMessageFE
  renderProductsComponent: (products: AssistantMessageProductDTO[] | undefined) => ReactNode
  error?: string
}

const ChatMessage: FC<ChatMessageProps> = ({ renderProductsComponent, msg, error, ...props }) => {
  const { settings } = useAssistantContext()
  const isBot = msg.role === 'assistant'

  if (isBot) {
    return (
      <HStack
        alignItems="flex-start"
        alignSelf="start"
        className="ai-widget__message"
        data-message-id={msg.id}
        gap={3}
        maxWidth="90%"
        width="full"
        {...props}>
        <Center borderRadius="50%" position="sticky" top={0}>
          <Avatar
            imageProps={{
              src: settings?.imgUrl
            }}
          />
        </Center>

        <VStack alignItems="flex-start" flex={1}>
          <GrandText
            bg="background.surface"
            borderRadius={6}
            fontSize="sm"
            fontWeight="normal"
            px={4}
            py={2}
            shadow="md"
            w="auto"
            whiteSpace="break-spaces">
            {msg.text}
          </GrandText>
          {renderProductsComponent(msg.products)}
        </VStack>
      </HStack>
    )
  }

  return (
    <HStack alignSelf="end" maxWidth="calc(90% - 45px)" {...props}>
      {!!error && (
        <GrandTooltip color="red" label={error}>
          <Box color="red">
            <IoMdAlert />
          </Box>
        </GrandTooltip>
      )}

      <Flex bg="#5ba2c0" borderRadius={6} color="text.light" px={4} py={2} shadow="md">
        <GrandText fontSize="sm" fontWeight="normal" w="full">
          {msg.text}
        </GrandText>
      </Flex>
    </HStack>
  )
}
export default ChatMessage
