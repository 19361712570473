import type { FC, ReactNode } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { Alert, AlertDescription, AlertIcon, Flex, HStack, VStack } from '@chakra-ui/react'
import { IoReload } from 'react-icons/io5'
import GrandIconButton from 'ui/common/GrandIconButton'
import type { AssistantMessageProductDTO } from 'ecosystem'
import BouncingDots from './BouncingDots'
import ChatMessage from './ChatMessage'
import { useAssistantContext } from './asssistantContext'

type ChatMessagesProps = StackProps & {
  renderProductsComponent: (products: AssistantMessageProductDTO[] | undefined) => ReactNode
}

const ChatMessages: FC<ChatMessagesProps> = ({ renderProductsComponent, sx, ...props }) => {
  const { history, errors, initError, isLoading, reinit } = useAssistantContext()

  return (
    <VStack
      gap={2}
      className="ai-widget__messages"
      id="assistant-messages"
      overflowX="hidden"
      overflowY="auto"
      overscrollBehaviorY="contain"
      p={4}
      pb={0}
      sx={{
        scrollbarWidth: 'none',
        ...sx
      }}
      {...props}>
      <VStack flex={1} gap={0} w="full">
        {history?.map((msg) => (
          <ChatMessage
            _first={{ pt: 0 }}
            error={errors[msg.id]}
            key={msg.id}
            msg={msg}
            pt={4}
            renderProductsComponent={renderProductsComponent}
          />
        ))}
      </VStack>

      {isLoading && (
        <Flex align="center" flex={0} justify="center" position="relative" pt={4} w="full">
          <BouncingDots />
        </Flex>
      )}

      {!!initError && (
        <Alert borderRadius="md" status="error">
          <HStack justifyContent="space-between" w="100%">
            <HStack>
              <AlertIcon />
              <AlertDescription>{initError}</AlertDescription>
            </HStack>

            <GrandIconButton
              aria-label="Reload assistant"
              fontSize="sm"
              icon={<IoReload />}
              minW={undefined}
              onClick={reinit}
              shadow="none"
              size="sm"
              tooltip="Retry"
              variant="ghost"
            />
          </HStack>
        </Alert>
      )}
    </VStack>
  )
}

export default ChatMessages
