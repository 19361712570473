import type { ChangeEvent, FC, KeyboardEvent, ReactNode } from 'react'
import { useCallback } from 'react'
import { Box, Grid, GridItem, IconButton, Input } from '@chakra-ui/react'
import { VscSend } from 'react-icons/vsc'
import type { AssistantMessageProductDTO } from 'ecosystem'
import ChatMessages from './ChatMessages'
import { useAssistantContext } from './asssistantContext'

interface ChatProps {
  renderProductsComponent: (products: AssistantMessageProductDTO[] | undefined) => ReactNode
}

const Chat: FC<ChatProps> = ({ renderProductsComponent }) => {
  const stateUpdater = useAssistantContext()
  const { inputValue: value, setInputValue, isLoading, handleSend, isInit, inputRef } = stateUpdater

  const sendMessage = useCallback(
    (text: string) => {
      if (isLoading || !isInit) {
        return
      }

      handleSend(text)
      setInputValue('')
    },
    [handleSend, isInit, isLoading, setInputValue]
  )

  return (
    <Grid
      className="ai-widget__chat"
      gap="1"
      h="full"
      templateColumns="repeat(1, 1fr)"
      templateRows="repeat(12, 1fr)"
      w="full">
      <GridItem colSpan={1} rowSpan={11}>
        <ChatMessages renderProductsComponent={renderProductsComponent} h="full" w="full" />
      </GridItem>

      <GridItem colSpan={1} rowSpan={1}>
        <Box pb={4} pt={2} px={4} w="full">
          <Box position="relative" w="full">
            <Input
              className="ai-widget__chat-input"
              bg="background.default"
              borderRadius={4}
              errorBorderColor="status.error"
              name="chat-input"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setInputValue(e.target.value)
              }}
              onKeyDown={(event: KeyboardEvent) => {
                if (event.key === 'Enter') {
                  sendMessage(value)
                }
              }}
              placeholder="Reply"
              pr="40px"
              ref={inputRef}
              value={value}
              variant="outline"
            />

            <IconButton
              aria-label="Send message"
              borderRadius="50%"
              icon={<VscSend />}
              isDisabled={!value?.length || isLoading || !isInit}
              onClick={() => sendMessage(value)}
              position="absolute"
              right={2}
              size="xs"
              top="50%"
              transform="translateY(-50%)"
              zIndex={2}
            />
          </Box>
        </Box>
      </GridItem>
    </Grid>
  )
}

export default Chat
