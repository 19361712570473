import type { FC } from 'react'
import type { FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'

type BouncingDotsProps = FlexProps

const BouncingDots: FC<BouncingDotsProps> = (props) => {
  return (
    <Flex
      css={`
        .bouncing-loader {
          display: flex;
          justify-content: center;
        }

        .bouncing-loader > div {
          width: 12px;
          height: 12px;
          margin: 3px 6px;
          border-radius: 50%;
          background-color: #a3a1a1;
          opacity: 1;
          animation: bouncing-loader 0.6s infinite alternate;
        }

        @keyframes bouncing-loader {
          to {
            opacity: 0.1;
            transform: translateY(-12px);
          }
        }

        .bouncing-loader > div:nth-of-type(2) {
          animation-delay: 0.2s;
        }

        .bouncing-loader > div:nth-of-type(3) {
          animation-delay: 0.4s;
        }
      `}
      {...props}>
      <div className="bouncing-loader">
        <div />
        <div />
        <div />
      </div>
    </Flex>
  )
}

export default BouncingDots
