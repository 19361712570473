import type { FC } from 'react'
import { Box, Grid, type StackProps, Text, VStack } from '@chakra-ui/react'
import { ImageBox } from 'ui'
import type { AssistantMessageProductDTO } from 'ecosystem'
import { Currency } from 'ecosystem'
import { commaSeparator } from 'shared-utils'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { useRouter } from 'next/navigation'
import type { PriceOverrides } from '../../products/components/Price'
import { useAssistantContext } from './asssistantContext'

interface ChatProductsProps extends StackProps {
  products?: AssistantMessageProductDTO[]
}

const ChatProducts: FC<ChatProductsProps> = ({ products, ...props }) => {
  const overrides = useOverridesContext<keyof PriceOverrides>()
  const { components, paths } = useAssistantContext()
  const router = useRouter()

  if (!products?.length) {
    return null
  }

  const renderPrice = (product: AssistantMessageProductDTO) => {
    if (components?.price?.isHidden) {
      return
    }

    const defaultRender = (
      <Box fontWeight="semibold" {...components?.price?.props}>
        {commaSeparator(product.price)} {overrideText(Currency.SEK, overrides?.priceCurrency)}
      </Box>
    )

    return components?.price?.render
      ? components?.price?.render({
          product,
          defaultRender
        })
      : defaultRender
  }

  if (!paths) {
    return (
      <Text color="red">{`ChatProducts error: "paths" is required in the context to properly route this component`}</Text>
    )
  }

  return (
    <Grid
      alignItems="stretch"
      gap={4}
      gridTemplateColumns="repeat(auto-fit, minmax(185px, 1fr))"
      w="100%"
      py={2}
      {...props}>
      {products?.map((product) => {
        return (
          <VStack
            alignItems="flex-start"
            className="chat-product-item"
            fontWeight="normal"
            key={product.name + product.slug}
            overflow="hidden"
            p={0}
            boxShadow="0 0px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
            borderRadius="md"
            cursor="pointer"
            onClick={() => {
              router.push(`${paths.products}/${product.slug}`)
            }}>
            <ImageBox
              h="100px"
              backgroundColor="background.surface"
              imageProps={{
                title: product.name,
                alt: product.name,
                src: product.imgUrl,
                fill: true,
                style: {
                  objectFit: 'contain'
                },
                sizes: '100px'
              }}
              w="100%"
              p={{
                base: 2,
                md: 2
              }}
            />

            <VStack
              alignItems="flex-start"
              fontSize="xs"
              p={2}
              pt={0}
              justifyContent="space-between"
              flex={1}>
              <VStack alignItems="flex-start" gap={1}>
                <Box color="text.mild" fontSize="2xs" textTransform="uppercase">
                  {product.brand}
                </Box>

                <Box fontWeight="semibold" fontSize="sm">
                  {product.name}
                </Box>

                {!!product.description && <Box>{product.description}</Box>}
              </VStack>
              {renderPrice(product)}
            </VStack>
          </VStack>
        )
      })}
    </Grid>
  )
}

export default ChatProducts
