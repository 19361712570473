'use client'
import { FC, useEffect } from 'react'
import { useDispatch } from 'shared-redux'
import { useWindowOnTop } from 'shared-redux/state/slices/window/useWindowOnTop'
import 'react-date-range/dist/styles.css' // react-formatge date styles
import 'react-date-range/dist/theme/default.css' // react-formatge date theme
import { setLocation } from 'shared-redux/state'
import { useSlideOut } from '#/src/custom/controllers'
import { usePathname } from 'next/navigation'
import { useScrollRestoration, useRouterChanged } from 'ui/hooks'

interface IInitAppProps {}

const InitApp: FC<IInitAppProps> = ({}) => {
  const { closeSlideOut, shouldCloseAfterRouterChanged } = useSlideOut()
  const dispatch = useDispatch()
  useScrollRestoration()
  useWindowOnTop()
  const pathname = usePathname()

  useEffect(() => {
    if (shouldCloseAfterRouterChanged) {
      closeSlideOut()
    }
  }, [pathname, closeSlideOut, shouldCloseAfterRouterChanged])

  useRouterChanged(({ data, meta }) => {
    if (meta.isPathChanged || meta.isSearchParamsChanged) {
      pathname && dispatch(setLocation({ prev: pathname, current: data.fullPath }))
    }
  })

  // ----- Google Analytics -------

  const handleRouteChange = (url: string) => {
    const metaTitle = document.getElementsByTagName('title')[0]
    window.gtag?.('event', 'page_view', {
      page_location: url,
      page_title: metaTitle?.innerText || ''
    })
  }

  /** send a page_view event on route reload */
  useEffect(() => {
    if (pathname) {
      handleRouteChange(pathname)
    }
  }, [pathname])

  return null
}

export default InitApp
