import { useDispatch, useSelector } from 'shared-redux'
import {
  selectAssistantIsOpen,
  selectAssistantSizeState,
  setIsOpen,
  setSizeState
} from 'shared-redux/state'
import { useCallback } from 'react'
import { assistantStorage } from 'shared-utils'

export const useAssistantSize = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectAssistantIsOpen)
  const isExpanded = useSelector(selectAssistantSizeState) === 'MAX'

  const openAssistant = useCallback(() => {
    dispatch(setIsOpen(true))
  }, [dispatch])

  const closeAssistant = useCallback(() => {
    dispatch(setIsOpen(false))
  }, [dispatch])

  const toggleAssistant = useCallback(() => {
    if (isOpen) {
      closeAssistant()
    } else {
      openAssistant()
    }
  }, [closeAssistant, isOpen, openAssistant])

  const expandSize = useCallback(() => {
    dispatch(setSizeState('MAX'))
    assistantStorage.setSizeState('MAX')
  }, [dispatch])

  const shrinkSize = useCallback(() => {
    dispatch(setSizeState('MIN'))
    assistantStorage.setSizeState('MIN')
  }, [dispatch])

  const toggleSize = useCallback(() => {
    if (isExpanded) {
      shrinkSize()
    } else {
      expandSize()
    }
  }, [expandSize, isExpanded, shrinkSize])

  return {
    isOpen,
    openAssistant,
    closeAssistant,
    toggleAssistant,
    isExpanded,
    toggleSize,
    expandSize,
    shrinkSize
  }
}
